import React from "react";
import Home from "./pages/Home";
import "./styles/App.css";
import Navbar from "./components/Navbar";
import Camera from "./pages/Camera";
import Mic from "./pages/Mic";
import Contact from "./pages/Contact";
import About from "./pages/About";
import { Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index path="" element={<Home />} />
          <Route path="camera" element={<Camera />} />
          <Route path="audio" element={<Mic />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<h1>NO PAGES FOUND</h1>} />
        </Route>
      </Routes>
    </>
  );
}
