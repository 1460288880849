import React, { useState } from "react";
import logo from "../assets/logo.png";
import ham from "../assets/ham.png";
import { Outlet, Link } from "react-router-dom";

export default function Navbar() {
  const [open, setOpen] = useState(0);
  function toggleMenu() {
    if (open === 0) {
      setOpen(1);
    } else {
      setOpen(0);
    }
  }
  return (
    <>
      <div className="navbar">
        <Link to="">
          <img className="logo-img" src={logo} alt="sarthagpt logo" />
        </Link>
        <ul className={`nav-items ${open ? "open" : "closed"}`}>
          <li className="nav-item">
            <Link to="">Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/about">About</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>

        <input
          hidden={true}
          class="check-icon"
          id="check-icon"
          name="check-icon"
          type="checkbox"
          onChange={toggleMenu}
        />
        <label class="icon-menu" for="check-icon">
          <div class="bar bar--1"></div>
          <div class="bar bar--2"></div>
          <div class="bar bar--3"></div>
        </label>
      </div>
      <Outlet />
    </>
  );
}
