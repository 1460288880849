import React, { useRef, useState } from "react";
import Answer from "../components/Answer";
import Webcam from "react-webcam";
import axios from "axios";
import Loading from "../components/Loading";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const Camera = () => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showAnswer, setShowAnswer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({});
  const [completedCrop, setCompletedCrop] = useState(null);

  async function captureImage() {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  }

  function getImageFromGallery(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setCapturedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const videoConstraints = {
    facingMode: "environment",
  };

  function replaceOriginalImage() {
    setCapturedImage(completedCrop);
  }
  async function askQuestion() {
    try {
      setLoading(true);
      await axios
        .post("/api/ask-with-image", { imageData: capturedImage })
        .then((res) => {
          setShowAnswer(res.data[0].message.content);
          setLoading(false);
        });
    } catch (error) {
      console.error("Error sending image to server:", error);
    }
  }

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onCropComplete = (crop) => {
    setCompletedCrop(crop);
    console.log(crop);
    console.log(capturedImage);
  };

  return (
    <>
      {loading && <Loading />}
      {showAnswer && <Answer answer={showAnswer} />}
      {!showAnswer && (
        <div className="camera-section">
          <p className="camera-head">Capture a photo of your question</p>

          <div className="after-cp">
            {capturedImage && (
              <>
                <img src={capturedImage} alt="capturedImage" />
              </>
            )}

            {capturedImage && (
              <button id="ask" className="btn proceed" onClick={askQuestion}>
                <span class="material-symbols-rounded">done</span>Proceed
              </button>
            )}
            {capturedImage && (
              <a href="/camera" id="retake-btn" className="btn again">
                <span class="material-symbols-rounded">replay</span>
                Try Again
              </a>
            )}
          </div>
          <div className="before-cp">
            {!capturedImage && (
              <Webcam
                id="webcam"
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            )}
            <div className="cam-btns">
              {!capturedImage && (
                <button id="capture-btn" onClick={captureImage}>
                  Capture Image
                </button>
              )}
              {!capturedImage && (
                <div className="select-from-gallery">
                  <label htmlFor="gallery-input">
                    <span class="material-symbols-rounded gallery">
                      photo_library
                    </span>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={getImageFromGallery}
                    name="gallery-input"
                    id="gallery-input"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Camera;
