import React from "react";

export default function Answer(props) {
  return (
    <div className="answer-section">
      <div className="ask-btns">
        <a href="/camera" className="card cam">
          <span className="material-symbols-rounded">filter_center_focus</span>
          <p className="card-head">Scan with Camera</p>
        </a>
        <a href="/audio" className="card voice">
          <span className="material-symbols-rounded">mic</span>
          <p className="card-head">Ask a question</p>
        </a>
        <a href="/" className="card voice">
          <span className="material-symbols-rounded">keyboard</span>
          <p className="card-head">type your Question</p>
        </a>
      </div>
      <p className="ans-head">SarthaGPT says,</p>
      <p className="answer">{props.answer}</p>
    </div>
  );
}
