import React from "react";
import wp from "../assets/Whatsapp.svg";
import ph from "../assets/Phone.svg";
import em from "../assets/Email.svg";
import ad from "../assets/Address.svg";

export default function Contact() {
  return (
    <div className="contact-page">
      <p className="footer-section-header">CONTACT US:</p>
      <a className="footer-link" href="mailto:info.sartha@gmail.com">
        <img className="contact-icon" src={em} alt="" />
        info.sartha@gmail.com
      </a>
      <a className="footer-link contact-icon" href="tel:72030 86849">
        <img className="contact-icon" src={ph} alt="" />
        72030 86849
      </a>
      <a className="footer-link contact-icon" href="https://wa.me/7203086849">
        <img className="contact-icon" src={wp} alt="" />
        72030 86849
      </a>
      <p className="footer-link">
        <img className="contact-icon" src={ad} alt="" /> D-347, Sumel business
        park 6, Dudheshwar, Ahmedabad, Gujarat 380004
      </p>
    </div>
  );
}
