import axios from "axios";
import React, { useState } from "react";
import Answer from "../components/Answer";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Loading from "../components/Loading";

const Dictaphone = () => {
  const [loading, setLoading] = useState(false);
  const [showAnswer, setShowAnswer] = useState(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const obj = {
    text: transcript,
  };
  async function askQuestionWithAudio() {
    try {
      setLoading(true);
      await axios.post("/api/ask-with-audio", obj).then((res) => {
        setShowAnswer(res.data[0].message.content);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error sending question to server:", error);
    }
  }

  return (
    <>
      {loading && <Loading />}
      {showAnswer && <Answer answer={showAnswer} />}
      {!showAnswer && (
        <div className="mic-section">
          <p className="mic-head">
            {!listening ? "Start Listening" : "Listening..."}
          </p>
          <div className="btns">
            {!listening && (
              <button
                className="btn mic-btn"
                onClick={SpeechRecognition.startListening}
              >
                <span class="material-symbols-rounded">mic</span>
              </button>
            )}
            {listening && (
              <button
                className="btn mic-btn stop"
                onClick={SpeechRecognition.stopListening}
              >
                <p>Stop</p>
              </button>
            )}
          </div>
          {transcript && (
            <div className="trans">
              <p className="transcript">
                Your questions is,
                <br />
                {transcript}
              </p>
              <div className="btns">
                <button className="btn proceed" onClick={askQuestionWithAudio}>
                  <span class="material-symbols-rounded">done</span>Proceed
                </button>
                <button className="btn again" onClick={resetTranscript}>
                  <span class="material-symbols-rounded">replay</span>
                  Try again
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default Dictaphone;
