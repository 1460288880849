import React from "react";

export default function Loading() {
  return (
    <div class="loader">
      <li class="ball"></li>
      <li class="ball"></li>
      <li class="ball"></li>
    </div>
  );
}
