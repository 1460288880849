import React from "react";

export default function About() {
  return (
    <div className="about-page">
      <>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          At SarthaGPT, we are on a mission to make learning and problem-solving
          easier and more accessible for everyone. Our innovative platform
          harnesses the power of AI to provide instant and accurate answers to
          your questions, regardless of the language or subject.
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          <u>
            <span style={{ textDecoration: "none" }}>&nbsp;</span>
          </u>
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          <strong>
            <u>Our Vision</u>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",
            fontSize: 15,
            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          We envision a world where knowledge knows no boundaries. With
          SarthaGPT, we strive to break down language barriers and simplify
          complex concepts, making education and information readily available
          to all.
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          <strong>
            <u>Why Choose SarthaGPT?</u>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          <strong>Instant Answers:</strong> Get answers within seconds, whether
          you type, scan, or speak your question.
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          <strong>Multi-Language Support:</strong> We understand that knowledge
          is universal, so we support questions in multiple languages.
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          <strong>Accuracy:</strong> Our AI-driven solutions are designed to
          provide precise and reliable information.
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          <strong>Dedicated Team:</strong> Our team of experts and developers is
          committed to enhancing your learning experience.
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          <strong>
            <u>Our Commitment</u>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          At SarthaGPT, we are dedicated to continuous improvement. We value
          your feedback and are constantly working to enhance our platform to
          better serve your needs. Your success is our success, and we are here
          to support you every step of the way.
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          Thank you for choosing SarthaGPT as your trusted source of knowledge
          and solutions. Together, we can make learning and problem-solving a
          seamless experience.
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            lineHeight: "115%",

            fontFamily: '"Arial",sans-serif',
          }}
        >
          &nbsp;
        </p>
      </>
    </div>
  );
}
