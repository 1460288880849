import React, { useState } from "react";
import axios from "axios";
import ill from "../assets/home_illustration.png";
import Answer from "../components/Answer";
import Loading from "../components/Loading";

export default function Home() {
  const [loading, setLoading] = useState(false);

  const [showAnswer, setShowAnswer] = useState(null);
  const [question, setQuestion] = useState(null);

  const inputHandler = (event) => {
    setQuestion(event.target.value);
  };

  const obj = {
    text: question,
  };
  async function askQuestionWithText() {
    try {
      setLoading(true);
      await axios.post("/api/ask-with-text", obj).then((res) => {
        setShowAnswer(res.data[0].message.content);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error sending question to server:", error);
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      askQuestionWithText();
    }
  };
  return (
    <>
      {loading && <Loading />}
      {showAnswer && <Answer answer={showAnswer} />}
      {!showAnswer && (
        <div id="home-page">
          <div className="main">
            <div className="upper">
              <h1 className="heading">
                Introducing <br /> SarthaGPT
              </h1>
              <p className="tag-line">Got a question? We've got the answer.</p>
            </div>
            <div className="playground">
              <a href="/camera" className="card cam">
                <p className="card-head">Scan with Camera</p>
                <span className="material-symbols-rounded">
                  filter_center_focus
                </span>
              </a>
              <a href="/audio" className="card voice">
                <p className="card-head">Ask a question</p>
                <span className="material-symbols-rounded">mic</span>
              </a>
              <p className="or"></p>
              <div className="card type">
                <button
                  type="submit"
                  onClick={askQuestionWithText}
                  className="send-btn"
                >
                  <span class="material-symbols-rounded">send</span>
                </button>
                <input
                  id="textq"
                  name="textq"
                  className="card-head textarea-card"
                  placeholder=" Type your question here"
                  onChange={inputHandler}
                  onKeyUp={handleKeyPress}
                ></input>
                <span className="material-symbols-rounded">keyboard</span>
              </div>
            </div>
          </div>
          <img src={ill} className="hero-ill" alt="Sartha GPT illustration" />
        </div>
      )}
    </>
  );
}
